import React from 'react';
import MenuItem from './MenuItem';
import './styles.scss';


interface IProps {
  data: any,
  currentUrl: string,
  mc: string
}

class MenuMobile extends React.Component<IProps> {
  render() {
    const { data, currentUrl, mc } = this.props;

    return (
      <>
      {!data || data.length < 0 ? null : 
        <div  id="ef-menu-mobile-gud" className="ef-product-menu--react">
          <div data-clicksubregion="site-menu-mobile">
            <nav className={`ef-nav`}>
              <ul className="erika-nav__menu">
                {data.map((item, i) => (
                    <MenuItem key={i} item={item} currentUrl={currentUrl} mc={mc} />
                  ))}
              </ul>
            </nav>
          </div>
        </div>
        }
      </>
    );
  }
}

export default MenuMobile;
