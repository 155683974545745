import React from "react";
import { BreakpointProvider } from '@ef-global/web-ui-react/lib/components/BreakpointContext'
import Layout from "./layout";
import BlankLayout from "./blankLayout";
import { SettingsContextProvider } from "../context/SettingsContext";
import { PageContextProvider } from "../context/PageContext";
import LangContextProvider from "../context/LangContext";

const Lang = ({ children, pageContext }) => {
  const settings = Object.assign({}, pageContext.globalSettings);
  if (typeof settings.content === "string") {
    settings.content = {} //JSON.parse(settings.content);
  }

  if (pageContext.layout === "blank") {
    return <BlankLayout pageContext={pageContext}>{children}</BlankLayout>;
  }
 
  return (
    <LangContextProvider lang={pageContext.story?.lang} slug={pageContext.story?.slug}>
      <SettingsContextProvider settings={settings}>
        <PageContextProvider data={pageContext}>
          <BreakpointProvider>
            <Layout pageContext={pageContext}>{children}</Layout>
          </BreakpointProvider>
          </PageContextProvider>
      </SettingsContextProvider>
    </LangContextProvider>
  );
};

export default Lang
