import React from 'react';
import {getNestedVals} from '@ilc-technology/cefcom-utils';
import MenuItem from './MenuItem';
import MoreMenuItem from './MoreMenuItem';
import './styles.scss';

interface IProps {
  data: any,
  currentUrl: string,
  mc: string
}

class Menu extends React.Component<IProps> {
  render() {
    const { data, currentUrl, mc } = this.props;
    const labels = getNestedVals(data, ['header', 'topbar', 'labels'], []);
    const moreMenuLabel = 'More';

    return (
      <>
      {!data || !data.length ? null : 
      <div data-clicksubregion="site-menu" className="cefcom-product-menu--react nav -desktop">
        <div id="ef-product-menu">
          <nav className={`ef-nav`}>
            <ul className="ef-nav__menu">
              { data.map((item, i) => (
                <React.Fragment key={i}>
                  <MenuItem item={item} currentUrl={currentUrl} mc={mc} />
                </React.Fragment>
              ))}
              {!moreMenuLabel || !moreMenuLabel.length ? null :
                <MoreMenuItem label={moreMenuLabel} />
              }
            </ul>
          </nav>
        </div>
      </div>
      }
      </>
    );
  }
}

export default Menu;
