import React from 'react';
import { getNestedVals } from "@ilc-technology/cefcom-utils";
import { isDesktop } from '@ilc-technology/cefcom-utils';
import './styles.scss';

interface IState {
  isOpen: boolean;
}

class LangToggle extends React.Component<IState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    try {

      // Ensure IOS recognise click outside
      if ('ontouchstart' in document.documentElement) {
        document.body.style.cursor = 'pointer';
      }
      document.addEventListener('click', this.handleClickOutside);
    } catch (err) {

    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef(node) {
    // @ts-ignore
    this.wrapperRef = node;
  }
  handleClick() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleClickOutside(event) {
    // @ts-ignore
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isOpen) {
        this.handleClick();
      }
    }
  }

  render() {
    const { toggleLinks } = this.props;
    let toggleLinksList = toggleLinks
    if (toggleLinks && !Array.isArray(toggleLinks) && Object.values(toggleLinks) && Array.isArray(Object.values(toggleLinks)[0])) {
      // @ts-ignore
      toggleLinksList = Object.values(toggleLinks)[0]
    }

    if (!toggleLinksList || !Array.isArray(toggleLinksList) || !toggleLinksList.length) {
      return null
    }

    // const anchorShortText = toggleLinksList && toggleLinksList[0] && toggleLinksList[0].content.menuTitle ? toggleLinksList[0].content.menuTitle : ''; 
    const shortText = getNestedVals(toggleLinksList, ['0', 'content', 'menuTitle'], []);
    const shortTextMobile = getNestedVals(toggleLinksList, ['0', 'content', 'shortTitle'], []);

    return (
      <div ref={this.setWrapperRef} onClick={this.handleClick} className="lang-toggle cefcom-top-bar__top-link">
        <ul className="gc-topbar-dd">
          <li className={`gc-topbar-dd__toggle ${this.state.isOpen ? ' -open' : ''}`}>
            <span>{isDesktop() && shortText ? shortText : shortTextMobile}</span>
            <span className="cefcom-icon -chevron-down" />
            <ul className={`gc-topbar-dd__submenu`}>


              {
                toggleLinksList.map(({ lang, content }, i) => {
                  return <>
                    {content.menuTitle.toLowerCase() !== shortText.toLowerCase() ?
                      <li key={i} className="gc-topbar-dd__link">
                        <a href={content.linkUrl ? content.linkUrl : ''} className="gc-bar-btn gc-topbar-dd__link-btn">

                          <span>
                            {isDesktop() && content.menuTitle ? content.menuTitle : content.shortTitle}</span>
                        </a>
                      </li> : null}
                  </>

                })
              }

            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default LangToggle;
