import React, { Component } from 'react';
import './styles.scss';
import { getNestedVals, getFormattedDate } from "@ilc-technology/cefcom-utils";
import { getLangFromBucket } from '../../../utils';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
    // this.toggleProductMenu = this.toggleProductMenu.bind(this);
  }
  componentDidMount() {
    if(!this.props.mc){
      return
    }
    fetch(`https://api.storyblok.com/v2/cdn/stories/shared-content/global-navigation/footer?language=${getLangFromBucket(this.props.mc)}&token=dOfyfIgWCmLBr04QIPs3Lgtt&cv=${getFormattedDate()}`)
      .then(response => response.json())
      .then(data => this.setState({ data: data }))
      .catch(error => { console.log(error) })
  }

  render() {
    const data = this.state;
    const {mc} = this.props;
    const footerData = getNestedVals(data, ['data', 'story', 'content', 'body', '0', 'content'], []);
    const logo = getNestedVals(footerData, ['0', 'columns', '0', 'content', '0', 'image', 'image'], []);
    const text = getNestedVals(footerData, ['1', 'columns', '0', 'content', '0', 'content'], []);
    const columns = getNestedVals(footerData, ['2', 'columns'], []);
    const marketCode = `${mc === 'we' ? '' : '/'  + mc}`;

    return ( mc ?
      <div className={`footer ${this.props.showBanner && 'cookie-banner__margin'}`}>
        <div className='footer__wrapper'>
          <img src={logo} alt={'Erika Insurance'}/>
          <p className='text'>{text}</p>
          <div className='footer__links'>
            {
              columns.map((column, i) => {
                const anchorText = getNestedVals(column, ['content', '0', 'content'], []);
                const url = getNestedVals(column, ['content', '0', 'link_address', 'cached_url'], []);
                return (
                  <div className='link' key={i}> <a href={marketCode + url}>{anchorText}</a></div>
                )
              })
            }
          </div>
        </div>
      </div>
    : null );
  }
}

export default Footer;
