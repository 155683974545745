import { getFormattedDate } from "@ilc-technology/cefcom-utils";
import * as React from "react";
import './styles.scss';
import { getCookie, setCookie } from './utils'
import {getLangFromBucket} from '../../../utils'
// Please note the cookie logic recently changed because of legal requirements:
// https://ef-ilc.atlassian.net/browse/CONTENT-19315

interface IProps {
  showBanner?: boolean,
  setIsCookieBannerOpened?: any,
  mc?: string
};
interface IState {
  isOpen: boolean;
  showBanner: boolean,
  cookieRead: boolean,
  data: any,
};


const cookieName = 'cookie-banner-visited';

export default class CookieBanner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      showBanner: true,
      cookieRead: false,
      data: []
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {

    const { mc } = this.props;
    if(!mc){
      return
    }
    const requestBase = 'https://api.storyblok.com/v1/cdn/stories/';
    const headerPath = '/shared-content/global-navigation/cookie';
    const token = 'token=dOfyfIgWCmLBr04QIPs3Lgtt';
    const resolveRelations = 'resolve_relations=cookie-banner';

    fetch(`${requestBase}${getLangFromBucket(mc)}${headerPath}?${token}&${resolveRelations}.Labels&cv=${getFormattedDate()}`)
      .then(response => response.json())
      .then(data => this.setState({ data: data['story'] }))
      .catch(error => { console.log(error) })

    if (typeof window !== "undefined") {
      const serviceLabels = this.state.data['content'];
      const { setIsCookieBannerOpened } = this.props
      // We control the showBanner inside StoryBlok
      if (!getCookie(cookieName) || serviceLabels && serviceLabels['showBanner'] === false) {
        this.setState({ cookieRead: true }, () => {
          this.setState({ isOpen: true })
          setIsCookieBannerOpened(true)
        })
      }

    }
  }

  handleClose() {
    const { setIsCookieBannerOpened } = this.props;
    setIsCookieBannerOpened(false)
    this.setState({ isOpen: false }, () => {
      setCookie(cookieName, true, 30)
      document.cookie = cookieName + "=" + true + ";" + 30 + ";path=/";
    })
  }

  render() {

    const { cookieRead, isOpen } = this.state;
    const { mc } = this.props;
    const serviceLabels = this.state.data['content'];
    const cookieText = serviceLabels && serviceLabels['Labels'].map((l, i) => l.slug === 'text' ? l.content.text : null);
    const cookieGotItText = serviceLabels && serviceLabels['Labels'].map((l, i) => l.slug === 'accept' ? l.content.text : null);
    const cookieTitle = serviceLabels && serviceLabels['Labels'].map((l, i) => l.slug === 'cookies' ? l.content.text : null);
    return ( mc ?
      <>
        {/* ${!this.state.cookieRead ? 'placeholder' : ''} */}
        <div className={`cookie-policy-message ${isOpen && 'open'}`}>
          <div className="cookie-policy-message__container">
            <div className="cookie-policy-message__wrapper">
              <div className="cookie-policy-message__message">
                <div>
                  <h4>{cookieTitle}</h4>
                </div>
                <div className='cookie-policy-message__text-wrapper'>
                  <div className='cookie-policy-message__text'>
                    <div dangerouslySetInnerHTML={{ __html: cookieText && cookieText.join('') }} />

                  </div>
                  <div className='cookie-policy-message__button-wrapper'>
                    <div onClick={this.handleClose} className="cookie-policy-message__button">
                      <span>{cookieGotItText}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    : null )
  }
}