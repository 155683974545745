import React from 'react';

interface IProps {
  item: any,
  currentUrl: string,
  mc: string
}
interface IState {
  isOpen: boolean
}

class MenuItem extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.renderItem = this.renderItem.bind(this);

    this.state = {
      isOpen: false,
    };
  }
  renderItem(item, currentUrl, key, subLink) {
    const { mc } = this.props;
    const { isOpen } = this.state;
    const isActive = currentUrl === item.content.linkUrl
    const marketCode = `${mc === 'we' ? '' : '/'  + mc}`;
    return <li
      //@ts-ignore
      key={`${key}-${item.content.menuTitle}` || null}
      className={`erika-nav__item ${isOpen ? ' -open ' : ''} ${subLink ? 'ef-nav__sub-link' : ''}`}>
      <a className={`ef-nav__link ${subLink && isActive ? ' -is-active ' : ''}`} href={marketCode +  item.content.linkUrl}>{item.content.menuTitle}</a>
    </li>;
  }

  render() {
    const { item, currentUrl } = this.props;
    return this.renderItem(item, currentUrl, null, null);
  }
}

export default MenuItem;
