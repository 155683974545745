import {isBrowserRender, getWindowHeight, isMobile} from '@ilc-technology/cefcom-utils';
import langToBuckets from '../../build-utils/lang-to-buckets.json'

export const loadStoryblokBridge = function(cb, token) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${token}`;
  script.onload = cb;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export const getParam = function(val) {
  var result = "";
  var tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function(item) {
      tmp = item.split("=");
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

export const setMenuMaxHeight = () => {
  try {
    if (!isBrowserRender() || !document || !window) {
      return false;
    }
    if (isBrowserRender()) {
      let headers = [];

      const headerMenu = document.querySelectorAll('.cefcom-top-bar');
      const productHeader = document.querySelectorAll('.cefcom-product-header');
      const mobileMenu = document.querySelector('.ef-product-menu--react nav.ef-nav');
      
      // Caclculate the height of what is considered the full Header (TopBar + ProductHeader)
      if (headerMenu && headerMenu.length) {
        // @ts-ignore
        headerMenu.forEach((el) => headers.push(el.offsetHeight));
      }
      if (productHeader && productHeader.length) {
        // @ts-ignore
        productHeader.forEach((el) => headers.push(el.offsetHeight));
      }

      const add = (a, b) => a + b;
      // @ts-ignore
      const headerHeight = headers.reduce(add);
      const windowHeight = getWindowHeight();
      const newHeight = windowHeight - headerHeight;
      
      // Apply the calculation to the mobileMenu to vertically align and provide a fixed height
      // @ts-ignore
      mobileMenu.setAttribute('style', `top: ${headerHeight}px; height: ${newHeight}px`);
    }
  } catch (error) {

  }
};

// Prevent Body Scroll
// when menu is open mobile/desktop
export const preventBodyScroll = (add) => {

  try {
    if (!isBrowserRender()) {
      return;
    }
    const cssClass = isMobile() ? 'prevent-body-scroll' : 'prevent-body-scroll-desk';
    const elementClassList = document.documentElement.classList;

    if (add) {
      elementClassList.add(cssClass);
    } else {
      elementClassList.remove(cssClass);
    }
  } catch (error) {

  }

};

// Get Bucket From Lang
export const getBucketFromLang = (lang) => {
  const market = langToBuckets.find(l => l.language.toLowerCase() === lang.toLowerCase())
  if(!market){
    throw new Error(`Cant find market for ${lang}`)
  }
  return market.slug
}

// Get Lang from Bucket
export const getLangFromBucket = (bucket) => {
  const market = langToBuckets.find(l => l.slug.toLowerCase() === bucket.toLowerCase())
  if(!market){
    throw new Error(`Cant find market for ${bucket}`)
  }
  return market.language.toLowerCase()
}