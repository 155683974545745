import React, {createContext, useState} from 'react'


const defaultValue = {
  lang: '',
  setLang: () => null,
  slug: '',
  setSlug: () => null
}

export const LangContext = createContext(defaultValue);

const LangContextProvider = (props) => {
  const {children} = props
  const [lang, setLang] = useState(props.lang)
  const [slug, setSlug] = useState(props.slug)

  return (
    <LangContext.Provider value={{lang, setLang, slug, setSlug}}>
      {children}
    </LangContext.Provider>
  )
}

export default LangContextProvider
