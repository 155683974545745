import React, {Component} from'react';
import './styles.scss';
import { preventBodyScroll, setMenuMaxHeight } from '../../../utils';
import LangToggle from './LangToggle';
import { getFormattedDate, getNestedVals } from "@ilc-technology/cefcom-utils";
import Menu from './Menu';
import MenuMobile from './MenuMobile';
class Header extends Component {
  constructor() {
    super();
    this.state = {
        headerData: [],
        headerDataMenu: [],
        headerDataToggleLinks: []
    };
    this.toggleProductMenu = this.toggleProductMenu.bind(this);
}
componentDidMount() {
  const { mc } = this.props;
  const requestBase = 'https://api.storyblok.com/v1/cdn/stories/';
  const headerPath = '/shared-content/global-navigation/header';
  const token = 'token=dOfyfIgWCmLBr04QIPs3Lgtt';
  const resolveRelations = 'resolve_relations=erika-header';
  const marketCode = mc === 'jp' ? 'ja' : mc === 'kr' ? 'ko' : mc === 'es' ? 'es-es' :  mc === 'we' ? ''  : mc;

  fetch(`${requestBase}${marketCode}${headerPath}?${token}&cv=${getFormattedDate()}`)
  .then(response => response.json())
  .then(data => this.setState({ headerData: data['story'] }))
  .catch(error => { console.log(error) })
  
  fetch(`${requestBase}${marketCode}${headerPath}?${token}&${resolveRelations}.menu&cv=${getFormattedDate()}`)
  .then(response => response.json())
  .then(data => this.setState({ headerDataMenu: data['story'] }))
  .catch(error => { console.log(error) })

  fetch(`${requestBase}${marketCode}${headerPath}?${token}&cv=${getFormattedDate()}&${resolveRelations}.toggleLinks`)
  .then(response => response.json())
  .then(data => this.setState({ headerDataToggleLinks: data['story'] }))
  .catch(error => { console.log(error) })
}

  toggleProductMenu() {
    this.setState({
      isProductMenuOpen: !this.state.isProductMenuOpen
    }, () => {
      const { isProductMenuOpen } = this.state;
      preventBodyScroll(isProductMenuOpen);

      // const scrollbarWidth = scrollbarSize() + 'px';

      // Toggle body and html classes outside the scope of React app
      if (typeof document !== 'undefined' && isProductMenuOpen) {
        document.documentElement.classList.add('mobile-nav-opened');

        // we don't need this for the mobile menu - just for the topbar on desktop
        // document.documentElement.style.marginRight = -scrollbarSize() + 'px';

        setMenuMaxHeight();
      } else {
        document.documentElement.classList.remove('mobile-nav-opened');

        // we don't need this for the mobile menu - just for the topbar on desktop
        // document.documentElement.style.marginRight = '0px';

      }
    });
  }
  render() {
    const {currentUrl, mc} = this.props;
    const { headerData, headerDataMenu, headerDataToggleLinks} = this.state;
    const menuData = getNestedVals(headerDataMenu, ['content'], []);
    const toggleLinksData = getNestedVals(headerDataToggleLinks, ['content'], []);

    const menuLinks = getNestedVals(menuData, ['menu'], []);
    const toggleLinks = getNestedVals(toggleLinksData, ['toggleLinks'], []);
    const logo = getNestedVals(headerData, ['content', 'logo', '0', 'image'], []);
    const secondaryLogo = getNestedVals(headerData, ['content', 'secondaryLogo', '0', 'image'], []);
    
    return ( mc ?
      <header className={`header`}>
        <div className="header__container">
          <div className="header__sitetitle">
            <a href={mc === 'we' ? '/' : '/' + mc + '/'}>
            <img src={logo.filename} alt={logo.alt} className="header__sitetitle-image" />
            </a>
          </div>
          <div className="header-right">
            <LangToggle toggleLinks={toggleLinks} />

            <div className="header__flag">
                <img src={secondaryLogo.filename} alt={secondaryLogo.alt} className="header__flag-image" />
            </div>
          </div>
        </div>
       
          <Menu data={menuLinks} currentUrl={currentUrl} mc={mc}/>
          <MenuMobile data={menuLinks} currentUrl={currentUrl} mc={mc}/>
          {/* eslint-disable-next-line */}
          <span role="menu" tabIndex={0} onClick={this.toggleProductMenu} className="erika__block-menu-toggle">
            <span className="-menu-icon" />
          </span>
      </header>
    : null);
  }
}

export default Header;
