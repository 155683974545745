import React from 'react';

interface IProps {
  item: any,
  currentUrl: string,
  mc: string
}
interface IState {
  isOpen: boolean,
  subLinkIsOpen: boolean,
  isWindow: boolean
}


class MenuItem extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.isChildActive = this.isChildActive.bind(this);
    this.isItemIsActive = this.isItemIsActive.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isOpen: this.isChildActive(props),
      subLinkIsOpen: false,
      isWindow: false
    };
  }

  handleClick(e) {

    // e.preventDefault();

    e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

componentDidMount() {
  if(typeof window !== 'undefined'){
    this.setState({isWindow: true})
  }
}

  isItemIsActive(item){
    try {
      const { isWindow } = this.state;
      let isActive = false;
        if (isWindow === true){
        const pathname = window.location.pathname;
        return item.link === pathname
      }
      return isActive
    } catch (error) {
      return false
    }
  }

  isChildActive(props) {
    const {item: {subMenu}} = props;
    if (!subMenu || !subMenu.length) {
      return false;
    }
    return subMenu.find((o) => { return this.isItemIsActive(o) === true;}) ? true : false;
  }

  renderItem (item, currentUrl, subLink, key) {
    const {title, link, subMenu} = item;
    const isSubMenu = subMenu && subMenu.length;
    const {isOpen } = this.state;
    const { mc } = this.props;
    const isActive = this.isItemIsActive(item) //.isActive; //currentUrl === link;
    const marketCode = `${mc === 'we' ? '' : '/'  + mc}`;
    
    return  <li onClick={this.handleClick}
    //@ts-ignore
    key={`${key}-${title}` || null}
    className={`ef-nav__item ${ isOpen ? ' -open ' : ''} ${subLink ? 'ef-nav__sub-link' : '' } ${isSubMenu ? ' -with-sub-menu ' : ''} ${this.isChildActive(this.props) ? '-has-active-child' : ''} `}>
      <a className={`ef-nav__link ${isActive ? '-is-active' : '' }`} href={marketCode + item.content.linkUrl}>{item.content.menuTitle}</a>
    </li>;
  }
  render() {
    const {item, currentUrl} = this.props;
    //@ts-ignore
    return this.renderItem(item, currentUrl);
  }
}

export default MenuItem;