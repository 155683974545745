// @ts-nocheck

import React, { Component } from 'react';
import { debounce } from '@ilc-technology/cefcom-utils';

interface IProps {
  label: string
}
class MoreMenuItem extends React.Component<IProps> {
  moreButton() {
    // Functionality specific to desktop
    if (window.innerWidth < 1024) {
      return;
    }

    // Clear html of more dropdown
    document.querySelector('.-more-btn .ef-nav__sub-menu').innerHTML = '';

    // Get margin-right of nav items and convert to number
    const isRtl = document.querySelector('.rtl') ? 'margin-left' : 'margin-right';
    const navItemMargin = parseInt(
      window
        .getComputedStyle(document.querySelector('.ef-nav__item.-with-sub-menu'))
        .getPropertyValue(isRtl)
        .replace('px', '')
    );

    // Get nav & nav item width
    const navWidth = document.querySelector('#ef-product-menu .ef-nav').offsetWidth;
    const moreButtonWidth = document.querySelector('.-more-btn').offsetWidth + navItemMargin;

    // Generate max nav width leaving space for the new More button itself.
    const maxNavWidth = navWidth - moreButtonWidth;
    const menuItems = document.querySelectorAll('#ef-product-menu .ef-nav__menu > .ef-nav__item:not(.-more-btn)');

    let menuItemsWidth = [],
      menuItemsCombinedWidth;

    menuItems.forEach((item) => {
      menuItemsWidth.push(item.offsetWidth + navItemMargin);

      menuItemsCombinedWidth = menuItemsWidth.reduce(function(a, b) {
        return a + b;
      }, 0);

      if (menuItemsCombinedWidth >= (maxNavWidth - 50)) {
        item.classList.add('-hide');
        document.querySelector('.-more-btn').classList.add('-show');

        const menuItem = item.querySelector('.ef-nav__link');
        document.querySelector('.-more-btn .ef-nav__sub-menu').innerHTML += `<li class=" ef-nav__item  ef-nav__sub-link">${menuItem.outerHTML}</li>`;
      }
    });

    if (menuItemsCombinedWidth < maxNavWidth) {
      // Hide More Button and Show all other buttons
      document.querySelector('.-more-btn').classList.remove('-show');

      document.querySelectorAll('.ef-nav__item.-hide').forEach((btn) => {
        btn.classList.remove('-hide');
      });
    }
  }

  componentDidMount() {
    this.moreButton();

    window.addEventListener('resize', debounce(this.moreButton));
  }

  render() {
    const { label } = this.props;
    return (
      <li className="ef-nav__item -with-sub-menu -more-btn">
        <a className="ef-nav__link" href="#">
          {label}
        </a>
        <ul className="ef-nav__sub-menu"></ul>
      </li>
    );
  }
}

export default MoreMenuItem;
